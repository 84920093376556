export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53')
];

export const server_loads = [0,5,6,7,8];

export const dictionary = {
		"/(marketing)": [48,[10]],
		"/(single-page)/auth/change-password": [50,[12]],
		"/(single-page)/auth/reset-password": [51,[12]],
		"/(single-page)/auth/verify": [~52,[12]],
		"/(app)/dashboard": [~13,[2,3]],
		"/(app)/dashboard/account/[id]": [~14,[2,3]],
		"/(app)/dashboard/admin": [~15,[2,3,4]],
		"/(app)/dashboard/admin/assignments": [~16,[2,3,4]],
		"/(app)/dashboard/admin/classifiers": [~17,[2,3,4]],
		"/(app)/dashboard/admin/organisations": [~18,[2,3,4]],
		"/(app)/dashboard/admin/users": [~19,[2,3,4]],
		"/(app)/dashboard/assignments": [~20,[2,3]],
		"/(app)/dashboard/assignments/[id]": [21,[2,3,5]],
		"/(app)/dashboard/assignments/[id]/automated-feedback": [22,[2,3,5,6]],
		"/(app)/dashboard/assignments/[id]/automated-feedback/[assistant]/examples/[exampleId]": [~23,[2,3,5,6,7]],
		"/(app)/dashboard/assignments/[id]/automated-feedback/[assistant]/new-example": [24,[2,3,5,6,7]],
		"/(app)/dashboard/assignments/[id]/groups": [~25,[2,3,5]],
		"/(app)/dashboard/assignments/[id]/settings": [~26,[2,3,5]],
		"/(app)/dashboard/assignments/[id]/simulator": [~27,[2,3,5]],
		"/(app)/dashboard/assignments/[id]/submissions": [~28,[2,3,5]],
		"/(app)/dashboard/assignments/[id]/submissions/[sub_id]": [~29,[2,3,5]],
		"/(app)/dashboard/assignments/[id]/tasks": [~30,[2,3,5]],
		"/(app)/dashboard/assignments/[id]/users": [~31,[2,3,5]],
		"/(app)/dashboard/assignments/[id]/users/invite": [32,[2,3,5]],
		"/(app)/dashboard/classifiers": [~33,[2,3]],
		"/(app)/dashboard/classifiers/[id]": [34,[2,3,8]],
		"/(app)/dashboard/classifiers/[id]/groups": [~35,[2,3,8]],
		"/(app)/dashboard/classifiers/[id]/labels": [36,[2,3,8]],
		"/(app)/dashboard/classifiers/[id]/settings": [~37,[2,3,8]],
		"/(app)/dashboard/classifiers/[id]/train-open": [~38,[2,3,8]],
		"/(app)/dashboard/classifiers/[id]/training-files": [~39,[2,3,8]],
		"/(app)/dashboard/classifiers/[id]/users": [~40,[2,3,8]],
		"/(app)/dashboard/groups": [~41,[2,3]],
		"/(app)/dashboard/invitations/created/[invitationId]": [~42,[2,3]],
		"/(app)/dashboard/organisations/[id]": [~43,[2,3]],
		"/(single-page)/login": [53,[12]],
		"/(onboarding)/registration/register/[invitationId]": [~49,[11]],
		"/(app)/student": [~44,[2,9]],
		"/(app)/student/account/[id]": [~45,[2,9]],
		"/(app)/student/assignments": [~46,[2,9]],
		"/(app)/student/assignments/[sub_id]": [~47,[2,9]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';